<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

      <el-card class="card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            学期详情
          </h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3 colInfo">
              <label>学期名称</label>
              <div class="colFonts">{{ruleForm.name | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>学年</label>
              <div class="colFonts">{{ruleForm.year | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>学期时间范围</label>
              <div class="colFonts">{{ruleForm.startDate | flNoValue}}
                至
                {{ruleForm.endDate | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>假期时间范围</label>
              <div class="colFonts">{{ruleForm.startHoliday | flNoValue}}
                至
                {{ruleForm.endHoliday | flNoValue}}</div>
            </div>
            <div class="col-lg-12 colInfo">
              <label>备注</label>
              <div class="colFonts" v-if="ruleForm.remark!=null && ruleForm.remark.length>0" v-html="ruleForm.remark">
              </div>
              <div class="colFonts" v-else>暂无数据</div>
            </div>
          </div>
          
        </div>
      </el-card>
      <div class="row">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        </div>
      </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        fullscreenLoading: false,
        ruleForm: {
          semesterId: '',
          name: '',
          year: '',
          startDate: '',
          endDate: '',
          startHoliday: '',
          endHoliday: '',
          remark: '',
        },
      };
    },
    methods: {
      // 返回
      onBack() {
        let _this = this;
        back(_this);
      },
    },
    filters: {
      flNoValue(obj) {
        if (obj == null || typeof(obj) == "undefined" || obj == '') {
          return '暂无数据';
        }
        return obj;
      }
    },
    created() {
      let _this = this;
      // 班级ID
      let dataID = _this.$route.params.id;

      //判断是否有传值过来
      if (dataID != null && typeof(dataID) != "undefined" && dataID != "") {
        _this.ruleForm.semesterId = dataID;
        var apiUrl = '/api/admin/semester/' + dataID;
        _this.fullscreenLoading = true;
        ajaxGet(_this, apiUrl, null, function(obj) {
          _this.ruleForm.name = obj.name;
          _this.ruleForm.year = obj.year;
          _this.ruleForm.startDate = dateFormat(obj.startDate);
          _this.ruleForm.endDate = dateFormat(obj.endDate);
          _this.ruleForm.startHoliday = dateFormat(obj.startHoliday);
          _this.ruleForm.endHoliday = dateFormat(obj.endHoliday);
          _this.ruleForm.remark = obj.remark;
          _this.fullscreenLoading = false;
        });
      } else {
        _this.back();
      }
    }
  }
</script>

<style>
</style>
