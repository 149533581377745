<!--
  处分管理 - 新增
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{ Title }}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="奖项名称" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入奖项名称" class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="奖励日期" prop="prizeDate">
                <el-date-picker value-format="yyyy-MM-dd" :key="random" :picker-options="pickerOptions"
                  v-model="ruleForm.prizeDate" type="date" placeholder="请选择学期范围内的日期" class="wMax formControl">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="奖励级别" prop="levelId">
                <el-cascader v-model="ruleForm.levelId" placeholder="请选择" style="width: 100% !important"
                  expand-trigger="hover" class="formControl" :props="defaultParams" :options="Levels"></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input class="formControl" type="textarea" :rows="4" maxlength="500" show-word-limit
                  placeholder="请输入内容" v-model="ruleForm.remark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            获奖名单
          </h3>
          <div class="card-tools">
            <ul class="nav">
              <li class="nav-item">
                <comLinkageClass ref="LinkageClass" comPlaceholder="请选择获奖学生" :comData="optAllStudent"
                  :comMultiple="true" @change="onChangeLinkageClass" comClass="width_400"></comLinkageClass>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body p-0">
          <el-table stripe :data="selectStudentList">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="studentName" label="学生"></el-table-column>
            <el-table-column prop="className" label="班级"></el-table-column>
            <el-table-column prop="studentNo" label="学号"></el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <el-button size="mini" type="danger" @click="onDeleteSelect(scope.row.studentId)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>

      <!--附件-->
      <el-card style="margin-top: 10px">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            附件
          </h3>
        </div>
        <div class="card-body">
          <comUpload :key="random" @onPictureHandleSuccess="onPictureHandleSuccess" :files="uploadFiles"></comUpload>
        </div>
      </el-card>

      <div class="row" style="margin-top: 10px">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import "@/assets/css/Admin.css";
  import comUpload from "@/components/Upload.vue";
  import comLinkageClass from "@/components/LinkageClass.vue";

  var prizeDateMin = null;
  var prizeDateMax = null;

  function disabledDateTest(date) {
    if (
      prizeDateMin != null &&
      typeof prizeDateMin != "undefined" &&
      prizeDateMax != null &&
      typeof prizeDateMax != "undefined"
    ) {
      return (
        new Date(date).getTime() < new Date(prizeDateMin).getTime() ||
        new Date(date).getTime() > new Date(prizeDateMax).getTime()
      );
    } else if (
      prizeDateMin != null &&
      typeof prizeDateMin != "undefined" &&
      (prizeDateMax == null || typeof prizeDateMax == "undefined")
    ) {
      return new Date(date).getTime() < new Date(prizeDateMin).getTime();
    } else if (
      (prizeDateMin == null || typeof prizeDateMin == "undefined") &&
      prizeDateMax != null &&
      typeof prizeDateMax != "undefined"
    ) {
      return new Date(date).getTime() > new Date(prizeDateMax).getTime();
    }
    return false;
  }
  export default {
    components: {
      comUpload,
      comLinkageClass,
    },
    data() {
      return {

        selectStudentList: [], //选中的学生集合
        random: 0, //刷新组件
        prizeDateMin: null,
        prizeDateMax: null,
        student: [],
        pickerOptions: {
          //奖励时间不能超出学期的时间范围
          disabledDate(time) {
            return disabledDateTest(time);
          },
        },
        //表单数据
        ruleForm: {
          prizeId: null, //奖励主键，新增时为null
          semesterId: null, //学期
          typeId: null, //奖励类型
          levelId: null, //奖励等级主键
          prizeDate: null, //奖励日期
          remark: null, //备注
          GrowthConfigs: null, //综合类型集合
          members: [],
          attachment: null,
        },
        ruleFormReset: null, //重置时的表单数据


        Levels: [], //奖励级别集合
        defaultParams: {
          label: "name", //要显示元素的名称
          value: "id", //显示元素对应的id
          children: "children", //子集名称
        },
        StudentList: [], //班级学生集合

        Title: "新增奖励信息",
        fullscreenLoading: false,
        uploadFiles: [],
        rules: {
          name: [{
              required: true,
              message: "奖项名称不能为空",
              trigger: "change",
            },
            {
              max: 50,
              message: "奖项名称不能超过50个字符",
              trigger: "change",
            },
          ],
          remark: [{
            max: 500,
            message: "备注不能超过500个字符",
            trigger: "change",
          }, ],
          levelId: [{
            required: true,
            message: "请选择奖励级别",
            trigger: "change",
          }, ],
          semesterId: [{
            required: true,
            message: "请选择学期",
            trigger: "change",
          }, ],
          prizeDate: [{
            required: true,
            message: "请选择奖励时间",
            trigger: "change",
          }, ],
          typeId: [{
            required: true,
            message: "请选择奖励类型",
            trigger: "change",
          }, ],
        },

        optAllStudent: [],
        optAllLevels: [],
      };
    },
    created() {
      let that = this;
      var url = that.$route.path.toLowerCase();

      that.fullscreenLoading = true;
      // 奖励级别
        ajaxGet( that, "/api/admin/prizeLevel/treelist",null, function(resLevel) {

            that.Levels = that.getTreeData(resLevel);
            that.optAllLevels = onGetTreeConvertList(resLevel);
            console.log(resLevel)
            let apiUrl = "/api/admin/student/bystatus?status=1";
            if (
              url == "/admin/adviser/studentprize/create" ||
              url.indexOf("/admin/adviser/studentprize/edit") != -1
            ) {
              // 班主任
              apiUrl += "&isAdviser=true";
            } else if (
              url == "/admin/studentprize/create" ||
              url.indexOf("/admin/studentprize/edit") != -1
            ) {
              // 管理员
              apiUrl += "&isAdviser=false";
            }

            // 获取学生数据
            ajaxGet(that, apiUrl, null, function(resStudent) {
              that.optAllStudent = resStudent;
              that.fullscreenLoading = false;
              // 调用组件，给组件传值
              that.$refs.LinkageClass.onGetData(resStudent);

              // 判断是否为编辑功能
              if (!regNull(that.$route.params.id)) {
                that.Title = "编辑奖励信息";
                ajaxGet(that, "/api/admin/prize/" + that.$route.params.id, null, function(resData) {
                  that.ruleForm = resData;
                  that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
                  that.onTidyAttachment(resData.attachment);

                  if (!regNullArray(resData.members)) {
                    // that.StudentList
                    let tempArr = resData.members.map((item) => item.studentId);
                    that.$refs.LinkageClass.onMultipleData(tempArr);
                    that.onChangeLinkageClass(tempArr)
                  }
                });
              } else {
                that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
              }
            });
          });
    },

    methods: {
      /**
       * @description 整理附件
       */
      onTidyAttachment(objItem) {
        let that = this;
        that.uploadFiles = [];
        // 整理附件
        if (!regNull(objItem)) {
          var files = objItem.split("|");
          for (var i in files) {
            var it = i + 1;
            that.uploadFiles.push({
              uid: it,
              path: files[i],
            });
          }
          var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
          that.random += random;
        }
      },
      /**
       * @description 联动选择触发事件
       * @param {Object} objItem
       */
      onChangeLinkageClass(objItem) {
        let that = this;
        that.selectStudentList = []; // 要重置一下数据，以免与控件无法联动
        if (!regNullArray(objItem)) {
          objItem.forEach((item) => {
            let temp = that.optAllStudent.find((fd) => fd.studentId == item);
            if (!regNull(temp)) {
              that.selectStudentList.push({
                studentName: temp.name,
                className: temp.class,
                studentNo: temp.studentNo,
                studentId: temp.studentId,
              });
            }
          });
        }
      },

      /**
       * @description 线型数据整理成树型数据
       * @param {*} data
       */
      getTreeData(data) {
        // 循环遍历json数据
        for (var i = 0; i < data.length; i++) {
          if (data[i].children.length < 1) {
            // children若为空数组，则将children设为undefined
            data[i].children = undefined;
          } else {
            // children若不为空数组，则继续 递归调用 本方法
            this.getTreeData(data[i].children);
          }
        }
        return data;
      },
      /**
       * @description 上传附件事件
       * @param {*} param
       */
      onPictureHandleSuccess(param) {
        let that = this;
        that.ruleForm.attachment = "";
        for (var i in param) {
          if (that.ruleForm.attachment == "") {
            that.ruleForm.attachment = param[i].path;
          } else {
            that.ruleForm.attachment += "|" + param[i].path;
          }
        }
      },

      /**
       * @description 奖励级别选择事件
       * @param {*} value
       */
      handleCascaderChange(value) {
        let that = this;
      },
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        let tempArr = that.ruleForm.members.map((item) => item.studentId);
        that.$refs.LinkageClass.onMultipleData(tempArr);
        that.onChangeLinkageClass(tempArr);
        that.onTidyAttachment(that.ruleForm.attachment);



        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      /**
       * @description 提交数据
       */
      onSave() {
        let that = this;
        that.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            // 判断是否选择了学生
            if (regNullArray(that.selectStudentList)) {
              warning(that, "请选择获奖学生");
              return false;
            }
            // 奖励类型
            // 级别数据
            let tempLevelId = typeof(that.ruleForm.levelId) == 'string' ? that.ruleForm.levelId : that.ruleForm
              .levelId[that.ruleForm.levelId.length - 1];
            let tpLevels = that.optAllLevels.find((item) => item.id == tempLevelId);
            console.log(tpLevels)
            let tpParams = {
              prizeId: that.ruleForm.prizeId,
              name: that.ruleForm.name,
              typeId: that.ruleForm.typeId,
              levelId: tempLevelId,
              prizeDate: that.ruleForm.prizeDate,
              attachment: that.ruleForm.attachment,
              remark: that.ruleForm.remark,
              // 获取的学生列表
              members: that.selectStudentList.map((item) => ({
                studentId: item.studentId,
              })),
              // 获得奖励的综合各维度所得的分数，分数=类型 X 级别
              configs: tpLevels.configs,
            };
            that.fullscreenLoading = true;
            if (that.ruleForm.prizeId == null) {
              ajaxPost(that, "/api/admin/prize", tpParams, function(r) {
                that.fullscreenLoading = false;
                that.onBack();
              });
            } else {
              ajaxPut(
                that,
                "/api/admin/prize/" + that.ruleForm.prizeId,
                tpParams,
                function(r) {
                  that.fullscreenLoading = false;
                  that.onBack();
                }
              );
            }
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector("input").focus();
            }, 1);
            return false;
          }
        });
      },
      /**
       * @description 删除学生操作
       * @param {*} studentId
       */
      onDeleteSelect(studentId) {
        let that = this;
        // 过滤要删除的学生
        let temp = JSON.parse(
          JSON.stringify(
            that.selectStudentList.filter((item) => item.studentId != studentId)
          )
        );
        that.selectStudentList = temp;
        that.$refs.LinkageClass.onMultipleData(regNullArray(temp) ? [] : temp.map((item) => item.studentId));
      },
      onBack() {
        let that = this;
        back(that);
      },
    },
  };
</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545ff, #2d8eff);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323e56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }

  .wMax {
    width: 100% !important;
  }
</style>
