<!--
  房源管理 - 新增
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <div class="callout callout-warning">
      <div><small>1、房间号前颜色为房间状态：灰色为关闭，彩色为开启，数字为床位总数</small></div>
    </div>

    <el-card class="box-card" v-for="floor in itemData.floors" :key="floor.floorId">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          楼层：{{floor.name}}
          <span class="spFloorEnable">启用状态：
            <span class="color-success" v-if="floor.enable">开启</span>
            <span class="color-gray" v-else>关闭</span>
          </span>
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="4" v-for="room in floor.rooms" :key="room.roomId">
            <div class="info-box">
              <span class="info-box-icon" :class="room.enable?'bg-primary':'bg-secondary'">
                {{room.beds.length}}
              </span>
              <div class="info-box-content" style="line-height: 1;">
                <span class="info-box-text">{{room.name}}</span>
              </div>
              <div @click="onQRcode(room)" class="divQRBox VerticalMid">
                <el-tooltip class="item" effect="dark" content="点击查看二维码" placement="top">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-rqcode"></use>
                  </svg>
                </el-tooltip>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>


    <div class="row">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>

    <!-- 二维码 -->
    <el-dialog title="宿舍二维码" :visible.sync="dialogVisibleRQcode" width="50%" :close-on-click-modal="false"
      :close-on-press-escape="false">
      <div class="divQrcodeBox" id="qrcode">
        <vue-qr ref="Qrcode" :logoSrc="imageQRCodeUrl" :text="RQcodeForm.url" :size="400">
        </vue-qr>
        <div class="divQRName">{{itemData.name}} / {{RQcodeForm.name}}</div>
      </div>
      <span slot="footer" class="dialog-footer" style="textAlign:center">
        <el-button @click="dialogVisibleRQcode = false">取 消</el-button>
        <el-button type="primary" @click="onDownload">下 载</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import html2canvas from 'html2canvas';
  import vueQr from 'vue-qr';
  export default {
    components: {
      vueQr,
    },
    data() {
      return {
        fullscreenLoading: false,
        itemData: [],
        buildingId: '',

        RQcodeForm: {
          name: null,
          roomId: null,
          url: null,
        },
        imageQRCodeUrl: require("@/assets/image/logo1.png"),
        dialogVisibleRQcode: false,
      };
    },
    methods: {
      /**
       * 下载二维码
       */
      onDownload() {
        let that = this;
        html2canvas(document.querySelector("#qrcode")).then(canvas => {
          let dataURL = canvas.toDataURL("image/png");
          if (dataURL !== "") {
            var a = document.createElement('a');
            a.download = that.itemData.name + '_' + that.RQcodeForm.name + '_二维码';
            a.href = dataURL;
            a.click();
          }
        });
      },


      //打开二维码
      onQRcode(objItem) {
        let that = this;
        that.RQcodeForm.name = objItem.name;
        that.RQcodeForm.roomId = objItem.roomId;
        that.RQcodeForm.url = that.$WebUrl() + "/mobile/#/pages/QRCode?Id=" + objItem.roomId+'&type=2';
        that.dialogVisibleRQcode = true;
      },
      // 返回
      onBack() {
        let that = this;
        back(that);
      },
    },
    created() {
      let that = this;
      that.buildingId = that.$route.params.id;
      //判断是否有传值过来
      if (that.buildingId != null && typeof(that.buildingId) != "undefined" && that.buildingId != "") {
        onGetData(that);
      }
    }

  };


  // 加载页面数据
  function onGetData(that) {
    that.fullscreenLoading = true;
    ajaxGet(that, '/api/admin/building/rooms/' + that.buildingId, null, function(resFloorData) {
      that.itemData.buildingId = resFloorData.buildingId;
      that.itemData.name = resFloorData.name;
      //冒泡排序房间
      for (var i = 0; i < resFloorData.floors.length; i++) {
        for (var j = 0; j < resFloorData.floors[i].rooms.length - 1; j++) {
          for (var k = 0; k < resFloorData.floors[i].rooms.length - 1 - j; k++) {
            if (parseInt(resFloorData.floors[i].rooms[k].name) > parseInt(resFloorData.floors[i].rooms[k + 1]
                .name)) {
              var temp = resFloorData.floors[i].rooms[k];
              resFloorData.floors[i].rooms[k] = resFloorData.floors[i].rooms[k + 1];
              resFloorData.floors[i].rooms[k + 1] = temp;
            }
          }
        }
      }
      //冒泡排序楼层
      for (var i = 0; i < resFloorData.floors.length - 1; i++) {
        for (var j = 0; j < resFloorData.floors.length - 1 - i; j++) {
          if (parseInt(resFloorData.floors[j].name) > parseInt(resFloorData.floors[j + 1].name)) {
            var temp = resFloorData.floors[j];
            resFloorData.floors[j] = resFloorData.floors[j + 1];
            resFloorData.floors[j + 1] = temp;
          }
        }
      }
      that.itemData.floors = resFloorData.floors;
      that.fullscreenLoading = false;
    });
  }

</script>

<style scoped="scoped" lang="less">
  .spFloorEnable {
    font-size: 14px;
    padding-left: 20px;
  }

  .divQrcodeBox {
    margin: 0 auto;
    border:1px solid #dedede;
    width:420px;
    height:460px;
    text-align: center;
    padding-top: 10px;
    .divQRName {
      font-size: 18px;
      text-align: center;
    }
  }


  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }


  .info-box {
    min-height: 40px;
    position: relative;

    .divQRBox {

      width: 40px;
      cursor: pointer;
    }
  }

  .info-box .info-box-icon {
    font-family: 'OswaldLight';
    width: 45px;
    font-size: 24px;
  }

  .info-box .info-box-text {
    font-size: 22px;
  }

  .divDropdownCont {
    width: 100%;
  }

</style>

